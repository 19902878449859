import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container,
  Grid,
  Typography,
  TextField,
  IconButton,
  Card,
  CardMedia,
  CircularProgress,
  Box,
  InputAdornment,
  Divider,
  Dialog,
  CardContent,
  Avatar,
  Button,
} from '@mui/material';
import {
  MoreVert,
  PlayCircle,
  Search as SearchIcon,
  Visibility,
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import { setDiscoverData } from '../../redux/reducers/userSlice';
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import RepeatIcon from '@mui/icons-material/Repeat';
import { debounce } from 'lodash';
import PublicProfilePage from './publicProfilePage';
import IndividualPostOverlay from './individualPostOverlay';
const DiscoverScreen = ({
  selectedChat,
  setMessages,
  tabValue,
  setTabValue,
  setSelectedChat,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [selectedMenu, setSelectedMenu] = useState('People');
  const [exploreData, setExploreData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchResult, setSearchResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [postData, setPostData] = useState(null);
  const [openPost, setOpenPost] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);

  const fetchExploreData = (page) => {
    setLoading(true);
    axios
      .get(
        `https://soapboxapi.megahoot.net/upload/trending/public/p?page=${page}&limit=15`
      )
      .then((res) => {
        if (res && res.data && res.data.results) {
          setExploreData((prev) => [...prev, ...res.data.results]);
          if (res.data.results.length === 0) {
            setHasMore(false); // If no more data, stop infinite scroll
          }
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const debouncedSearch = useCallback(
    debounce((text) => {
      if (text && text.length > 0) {
        axios
          .get(
            `https://soapboxapi.megahoot.net/user/search/p?keyword=${text}&page=1&limit=10`
          )
          .then((res) => {
            setSearchResult(res.data.results);
          })
          .catch((err) => console.log(err));
      } else {
        setSearchResult([]);
      }
    }, 500), // 500ms delay
    []
  );

  const handleSearch = (event) => {
    const text = event.target.value;
    debouncedSearch(text);
  };

  const handleProfileClick = (item) => {
    // Navigate to public profile page
  };

  const renderItem = (item) => (
    <Card
      key={item.id}
      style={{ margin: 'auto', width: '100%', position: 'relative' }}
    >
      <CardMedia
        onClick={() => {
          setOpenPost(true);
          setPostData(item);
        }}
        component={item.mimeType?.includes('image') ? 'img' : 'video'}
        image={`https://soapboxapi.megahoot.net/images/${item.image}`}
        onMouseEnter={(e) => {
          if (item.mimeType?.includes('video')) {
            e.target.play();
          }
        }}
        onMouseLeave={(e) => {
          if (item.mimeType?.includes('video')) {
            e.target.pause();
            e.target.currentTime = 0; // Optional: reset video to start
          }
        }}
        style={{
          height: 380,
          objectFit: 'cover',
          width: '100%',
        }}
        controls={false} // Disable default controls for hover functionality
        muted={true}
      />
      <IconButton
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',

          zIndex: 2,
        }}
        onClick={() => {
          setOpenPost(true);
          setPostData(item);
        }}
      >
        {item.mimeType?.includes('video') && (
          <PlayCircle
            sx={{
              fontSize: 40,
              color: '#fff',
            }}
          />
        )}
      </IconButton>
    </Card>
  );

  const renderUser = (item) => (
    // <Link to={`/profile/${item?.username}`} style={{ textDecoration: 'none' }}>
    <Box
      key={item.id}
      display="flex"
      alignItems="center"
      p={1}
      m={1}
      style={{ width: '100%' }}
      onClick={() => {
        setSelectedProfile(item);
        setShowProfile(true);
      }}
    >
      <CardMedia
        component="img"
        image={`https://soapboxapi.megahoot.net/profile-pictures/${item.profilePic}`}
        style={{ width: 50, height: 50, borderRadius: '50%' }}
        loading="lazy"
      />
      <Box ml={2}>
        <Typography variant="body1">
          {item.name}{' '}
          {item.verified === 1 ? (
            <>
              {item?.badge === 'Premium' ? (
                <img
                  src="/PremiumVerified.svg"
                  height="18px"
                  width="18px"
                  alt="premium_verified"
                />
              ) : item?.badge === 'Corporate' ? (
                <img
                  src="/PremiumVerified.svg"
                  height="18px"
                  width="18px"
                  alt="corporate_verified"
                />
              ) : item?.badge === 'Notable' ? (
                <img
                  src="/PremiumVerified.svg"
                  height="18px"
                  width="18px"
                  alt="corporate_verified"
                />
              ) : item?.badge === 'General' ? (
                <img
                  src="/PurpleVerified.svg"
                  height="18px"
                  width="18px"
                  alt="corporate_verified"
                />
              ) : item?.badge === 'Media' ? (
                <img
                  src="/MediaVerified.svg"
                  height="18px"
                  width="18px"
                  alt="corporate_verified"
                />
              ) : (
                <img
                  src="/RegularVerified.svg"
                  height="18px"
                  width="18px"
                  alt="regular_verified"
                />
              )}
            </>
          ) : null}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          @{item.username}
        </Typography>
      </Box>
    </Box>
    // </Link>
  );

  useEffect(() => {
    fetchExploreData(page);
  }, [page]);

  return (
    <Container maxWidth="lg">
      <Box display="flex" alignItems="center" mb={2} mt={1}>
        <TextField
          variant="outlined"
          placeholder="Search"
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth
        />
      </Box>

      <Grid container spacing={2}>
        {/* Right Panel for Search Results */}

        {/* Left Panel for Explore Data */}
        <Grid item xs={12} md={12}>
          {searchResult && searchResult.length > 0 ? (
            searchResult.map((user, index) => {
              return renderUser(user);
            })
          ) : (
            <Box style={{ height: 'calc(100vh - 64px)', overflow: 'auto' }}>
              <InfiniteScroll
                scrollThreshold={0.7}
                height={'calc(100vh - 64px)'}
                dataLength={exploreData.length}
                next={() => setPage((prev) => prev + 1)}
                hasMore={hasMore}
                loader={
                  <Box display="flex" justifyContent="center" my={4}>
                    <CircularProgress />
                  </Box>
                }
                endMessage={
                  <Typography
                    variant="body2"
                    align="center"
                    color="textSecondary"
                  >
                    No more results
                  </Typography>
                }
              >
                <Grid container spacing={2}>
                  {exploreData.map((item) => (
                    <Grid item xs={12} sm={6} md={3} key={item.id}>
                      {renderItem(item)}
                    </Grid>
                  ))}
                </Grid>
              </InfiniteScroll>
            </Box>
          )}
        </Grid>
      </Grid>
      {postData && postData.id && (
        <Dialog
          open={openPost}
          onClose={() => {
            setOpenPost(false);
            setPostData(null);
          }}
          maxWidth="md"
        >
          <IndividualPostOverlay postData={postData} />
        </Dialog>
      )}

      <Dialog
        maxWidth="xl"
        open={showProfile}
        fullWidth={true}
        onClose={() => setShowProfile(false)}
      >
        <PublicProfilePage
          username={selectedProfile?.username}
          selectedChat={selectedChat}
          setSelectedChat={setSelectedChat}
          setPage={setPage}
          setMessages={setMessages}
          tabValue={tabValue}
          setTabValue={setTabValue}
        />
      </Dialog>
    </Container>
  );
};

export default DiscoverScreen;
